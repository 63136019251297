#slide img{
    box-shadow: 1px 4px 5px 1px rgb(243, 159, 3) ;
    border: 1px solid rgb(247, 220, 170);
    transition: .8s;
    border-radius: 20px;
    /* transform:scale(1.2) */
}
#slide img:hover{
    translate: 0px -25px ;
}
h4{
    text-decoration: overline;
    text-align: center;
    color: rgb(255, 117, 5);
    font-size: 30px;
}